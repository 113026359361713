<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  darkMode: boolean
  colorShade: 'text-200'
  size: number
}>()

// This follows `text-dark-text` and `text-light-text` in Orizuru
const iconColor = computed(() => {
  switch (props.colorShade) {
    case 'text-200':
      return props.darkMode ? 'white' : 'black'
    default:
      return props.darkMode ? 'white' : 'black'
  }
})
const iconOpacity = computed(() => {
  switch (props.colorShade) {
    case 'text-200':
      return props.darkMode ? '0.7' : '0.54'
    default:
      return 1
  }
})

// This is Orizuru selection color
const indicatorColor = computed(() => (props.darkMode ? '#FEC006' : '#9466E8'))
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="overflow-visible"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0091 4.78875C13.734 3.6757 12.0682 3 10.25 3C6.25778 3 3 6.25778 3 10.25C3 14.2422 6.25778 17.5 10.25 17.5C11.8866 17.5 13.3945 16.9465 14.6104 16.0254L19.293 20.707C19.3851 20.803 19.4955 20.8796 19.6176 20.9324C19.7397 20.9852 19.8712 21.0131 20.0042 21.0144C20.1373 21.0158 20.2692 20.9906 20.3924 20.9403C20.5156 20.89 20.6275 20.8157 20.7216 20.7216C20.8157 20.6275 20.89 20.5156 20.9403 20.3924C20.9906 20.2692 21.0158 20.1373 21.0144 20.0042C21.0131 19.8712 20.9852 19.7397 20.9324 19.6176C20.8796 19.4955 20.803 19.3851 20.707 19.293L16.0254 14.6104C16.9465 13.3945 17.5 11.8866 17.5 10.25C17.5 9.61267 17.417 8.99406 17.2612 8.40443C15.9884 7.67305 15.1072 6.33726 15.0091 4.78875ZM15.5 10.25C15.5 7.33866 13.1613 5 10.25 5C7.33866 5 5 7.33866 5 10.25C5 13.1613 7.33866 15.5 10.25 15.5C11.6489 15.5 12.9121 14.9562 13.8506 14.0723C13.9128 13.9863 13.9883 13.9108 14.0742 13.8486C14.957 12.9103 15.5 11.6479 15.5 10.25Z"
      :fill="iconColor"
      :fill-opacity="iconOpacity"
    />
    <path
      d="M23 4.5C23 6.433 21.433 8 19.5 8C17.567 8 16 6.433 16 4.5C16 2.567 17.567 1 19.5 1C21.433 1 23 2.567 23 4.5Z"
      :fill="indicatorColor"
    />
  </svg>
</template>
